@import 'App/Resources/styles/config';

.container {
  background-color: var(--color-hover4);

  h3 {
    color: var(--text-color1);
  }

  :global(.vc_column-inner) {
    background-color: #ffffff;
  }

  &:nth-child(odd) {
    background-color: var(--color4);
  }
}

.etHeading {
  :global(.text-wrapper) {
    background-color: transparent;
    padding: 0;
  }

  color: $mainColor;
  font-weight: 700;
  line-height: 24px;
  text-transform: none;

  a {
    color: $mainColor;
  }

  margin: 0px 0px 8px 0px;

  :global(.text-wrapper) {
    padding: 0px 0px 0px 0px;
  }
}

.etSeparator {
  :global(.line) {
    margin-top: 8px;
    margin-bottom: 24px;
    border-bottom-color: #e0e0e0;
    border-bottom-style: solid;
  }
}

.etIconList {
  :global(.icon) {
    color: $color2;
    border-radius: 0px;
  }
}
