// :root {
//   --color1: #002855;
//   --color-hover1: #a41726;
//   --color2: #313131;
//   --color-hover2: #4e4e4e;
//   --color3: #4e4e4e;
//   --color-hover3: #737373;
//   --color4: #737373;
//   --color-hover4: #5f5f5f;
//   --color5: #777777;
//   --color-hover5: #575757;
//   --cmp-color1: #777777;
//   --cmp-hover-color1: #575757;
//   --text-color1: #ffffff;
//   --text-color-hover1: #ffffff;
//   --text-color2: #313131;
//   --text-color-hover2: #4e4e4e;
//   --text-color3: #ffffff;
//   --button-color1: #c81c2e;
//   --button-color-hover1: #a41726;
// }

// $size_mobile: 425px;
// $size_tablet: 768px;
// $size_laptop: 1024px;

// $device_mobile: "max-width:" $size_mobile;
// $device_tablet: "max-width:" $size_tablet;
// $device_laptop: "max-width:" $size_laptop;

// Brand Colors
// $brand-blue:   #002855;
// $color-mint-green: #00a19c;
// $color-light-grey-bg: #fcfaf9;
// $color-barely-grey: $color-light-grey-bg;
// $color-light-grey: #f2efed;
// $color-grey-bg: $color-light-grey;
// $color-grey: #caccc3;
// $color-dark-grey: #6f706b;
// $color-black: #0f1108;
// $color-light-black: #212121;
// $color-white: #ffffff;
// $color-orange: #ff9b42;
// $color-red: #ec2424;
// $color-green: #00c800;
// $color-light-green: #6fcf97;
// $color-dark-green: #2a9435;
// $color-grey-text-on-white: #9fa09c;
// $color-link-blue: #3772ff;

// next
$color-mint-green: #00a19c;
$color-light-grey-bg: #fcfaf9;
$color-barely-grey: $color-light-grey-bg;
$color-light-grey: #ddd;               // +
// $color-light-grey: #f5f5f5;               // +
$color-grey-bg: $color-light-grey;
$color-grey: #e0e0e0;                     // +
$color-dark-grey: #616161;                // +
$color-black: #0f1108;
$color-light-black: #212121;
$color-white: #ffffff;
$color-orange: #e05c0b;                   // +
$color-red: #ec2424;
$color-green: #00c800;
$color-light-green: #6fcf97;
$color-dark-green: #2a9435;
$color-grey-text-on-white: #9fa09c;
$color-link-blue: #3772ff;
$color-yellow: #ffd800;

$color-main-light: #094977;
$color-main: #033c63;
$color-main-dark: #1e232e;

$brand-blue: $color-main;
// $brand-red: #da291c;
$brand-red: #ff0000;
$brand-yellow: rgb(244, 167, 0);
$brand-grey: #8a8d8f;
$brand-white: #ffffff;

// Color Roles
$primary: $color-main;
// $primary-hover: $color-dark-grey;
$primary-hover: $color-main-light;
// $primary:   $brand-blue;
// $primary-hover:   lighten($brand-blue, 15%);
$secondary: $brand-grey;
$secondary-hover: lighten($brand-grey, 40%);
$warning: $brand-yellow;
$warning-hover: lighten($brand-yellow, 10%);
$danger: $brand-red;
$danger-hover: lighten($brand-red, 5%);
$dark: #333333;
$dark-hover: lighten($dark, 10%);
$light: $brand-white;
$grey: $brand-grey;
$light-grey: #efefef;

// Typography
$font-mono: 'Courier', monospace;
$font-sans: 'Bosch Sans', Montserrat, 'Source Sans Pro', Arial, sans-serif;
$font-serif: 'Bree Serif', Times, serif;
$base-font-color: $brand-blue;
$base-font-family: $font-sans;
$base-font-size: 16px;
$font-normal: 400;
$font-semibold: 600;
$font-bold: 700;
$base-line-height: 1.5;
$headings: (6, 5, 4, 3, 2, 1);

// Borders
$base-border: 1px solid $brand-grey;
$base-radius: 0em;

// Shadows
// $base-box-shadow: rgba($brand-grey, 0.4), 0 0 5px;
$base-box-shadow: rgba($color-main-dark, 0.4), 0 0 5px;
// $base-box-shadow: rgba($color-main-dark, 0.4), 3px 3px 10px 0px;
$big-box-shadow: rgba($color-black, 0.4), 0 16px 24px 0;
// $base-box-shadow: rgba(darken($brand-grey, 25%), 0.2) 0 0 5px;

// Links
$base-link-color: $primary;

// Tables
$base-table-border: 2px solid $primary;
$base-table-header-background: $primary;

// Preloaders
$base-preloader-radius: 30px;
$base-preloader-color: $secondary;

/// Compability settings ///
// основной
$mainColor: $brand-blue;

// логотип
$color2: $brand-red;

// элементы оформления
$color3: $brand-grey;

// шрифт, фон
$color4: $brand-white;
